<template>
	<section>
		<b-card>

			<b-row>
				<b-col md="12" class="mb-2">	
					<vue-dropzone  
						ref="myVueDropzoneRef" 
						id="myVueDropzone"			
						@vdropzone-success-multiple="vsuccess"					
						@vdropzone-sending-multiple="vsendingMultiple"										
						@vdropzone-files-added="vfilesAdded"
						@vdropzone-error="verror"
						@vdropzone-error-multiple="verrorMultiple"
						@vdropzone-canceled="vcanceled"
						:options="dropzoneOptions"
						:headers="headers"
						:useCustomSlot="true"				
					>
						<div class="dropzone-custom-content">
							<h4 class="dropzone-custom-title"><strong>¡Arrastre o haga click aquí para cargar las imágenes!</strong></h4>
							<div class="subtitle">formato: webp</div>
						</div>
					</vue-dropzone>    
				</b-col>
				<b-col>                
					<b-alert  variant="info" :show="true">
						<div class="alert-body">
							<ul class="mb-0">
								<li>
									Comprime y optimiza tus imágenes en 
									<b-link
										class="alert-link"
										href="https://tinypng.com/"
										target="_blank"
									>
										Tinify
									</b-link>
								</li>
								<li>
									Convierte tus imágenes a .webp en 
									<b-link
										class="alert-link"
										href="https://pixelied.com/convert/jpg-converter"
										target="_blank"
									>
										Pixelied
									</b-link>
								</li>
							</ul>
						</div>
					</b-alert>
				</b-col>
			</b-row>

			<!-- Form: Info Form -->
			<validation-observer ref="simpleRules">
				<b-form>
					<b-row>
						<b-col cols="12">
							<b-row>						
								<!-- Product Name -->
								<b-col
									cols="12"
									md="8"
								>							
									<b-form-group
										label="Nombre del producto"
										label-for="product-name"
									>
										<validation-provider
											#default="{ errors }"
											rules="required"
											name="nombre del producto"
										>
											<b-form-input
												id="product-name"
												v-model="name"
												placeholder="Nombre del producto ..."
												:state="errors.length > 0 ? false : null"
												trim
											/>
											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>
									</b-form-group>
								</b-col>
								
								<!-- Product Stock -->
								<b-col
									cols="3"
									md="4"
								>
									<b-form-group
										label="Stock"
										label-for="product-stock"
									>
										<validation-provider
											#default="{ errors }"
											rules="required"
											name="stock del producto"
										>
											<b-form-input
												id="product-stock"
												v-model="stock"
												class="form-control"
												placeholder="Stock del producto ..."
												:state="errors.length > 0 ? false : null"
												trim
											/>
											<small class="text-danger">{{ errors[0] }}</small>
                            			</validation-provider>
									</b-form-group>
								</b-col>
								
								<!-- Product Type -->
								<b-col
									cols="6"
									md="6"
								>
									<b-form-group
										label="Tipo de producto"
										label-for="product-type"
									>
										<validation-provider
											#default="{ errors }"
											rules="required"
											name="tipo de producto"
										>
											<v-select
												id="product-type"
												v-model="product_type"
												:options="product_typeOptions"
												label="name"
												:state="errors.length > 0 ? false:null"
											/>
											<small class="text-danger">{{ errors[0] }}</small>
                           	 			</validation-provider>					
									</b-form-group>
								</b-col>
								
								<!-- Product Category -->
								<b-col
									cols="6"
									md="6"
								>
									<b-form-group
										label="Categoría"
										label-for="product-category"
									>
										<validation-provider
											#default="{ errors }"
											rules="required"
											name="categoría"
										>
											<v-select
												id="product-category"
												v-model="category"
												:options="categoryOptions"
												:state="errors.length > 0 ? false:null"
												label="name"
											/>
											<small class="text-danger">{{ errors[0] }}</small>
                            			</validation-provider>				
									</b-form-group>
								</b-col>
								
								<!-- Product Brand -->
								<b-col
									cols="6"
									md="6"
								>
									<b-form-group
										label="Marca"
										label-for="product-brand"
									>
										<validation-provider
											#default="{ errors }"
											rules="required"
											name="marca"
										>
											<v-select
												id="product-brand"
												v-model="brand"
												:options="brandOptions"
												:state="errors.length > 0 ? false:null"
												label="name"
											/>
											<small class="text-danger">{{ errors[0] }}</small>
                            			</validation-provider>				
									</b-form-group>
								</b-col>
								
								<!-- Product Tags -->
								<b-col
									cols="6"
									md="6"
								>
									<b-form-group
										label="Etiqueta(s)"
										label-for="product-tag"
									>
										<validation-provider
											#default="{ errors }"
											rules="required"
											name="tag(s)"
										>
											<v-select
												v-model="tag"
												:options="tagOptions"
												:state="errors.length > 0 ? false:null"
												label="name"
												multiple
											/>
											<small class="text-danger">{{ errors[0] }}</small>
                            			</validation-provider>			
									</b-form-group>
								</b-col>
																	
								<!-- Product Description -->
								<b-col
									cols="12"
								>
									<b-form-group
										label="Descripción"
										label-for="product-description"
									>
										<validation-provider
											#default="{ errors }"
											rules="required"
											name="descripción"
										>
											<quill-editor
												v-model="description"
												:options="snowOption"
												:state="(errors.length > 0 ? false:null)"
											/>
											<!-- <b-form-textarea
												id="product-description"
												v-model="description"
												:state="(errors.length > 0 ? false:null)"
												class="char-textarea"
												placeholder="Descripción ..."
                                    			rows="3"
											/>
											<small
												class="textarea-counter-value float-right"
											>
												<span class="char-count">Caracteres: {{ description.length }}</span>
											</small> -->
											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>					
									</b-form-group>
								</b-col>

								<!-- Product Description SEO -->
								<b-col
									cols="12"
								>
									<b-form-group
										label="Descripción (SEO)"
										label-for="product-description-seo"
									>
										<validation-provider
											#default="{ errors }"
											rules="required"
											name="descripción (SEO)"
										>
				
											<b-form-textarea
												id="product-description-seo"
												v-model="description_seo"
												:state="(errors.length > 0 ? false:null)"
												class="char-textarea"
												placeholder="Descripción (SEO) ..."
                                    			rows="3"
											/>
											<small
												class="textarea-counter-value float-right"
											>
												<span class="char-count">Caracteres: {{ description_seo.length }}</span>
											</small>
											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>					
									</b-form-group>
								</b-col>
								
								<!-- Product Discount Type -->
								<b-col
									cols="6"
									md="6"
								>
									<b-form-group
										label="Tipo de descuento"
										label-for="discount-name"
									>
										<validation-provider
											#default="{ errors }"
											rules="required"
											name="tipo de descuento"
										>
											<v-select
												id="discount-name"
												v-model="discount"
												:options="discountOptions"
												:state="errors.length > 0 ? false:null"
												label="type"
											/>
											<small class="text-danger">{{ errors[0] }}</small>
                           	 			</validation-provider>		
									</b-form-group>
								</b-col>
								
								<!-- Product Discount Amount -->
								<b-col
									cols="6"
									md="3"
									v-if="discount && discount.type != 'Ninguno'"
								>
									<b-form-group
										:label="discount ? discount.type == 'Soles' ? 'Monto descuento' : 'Porcentaje descuento' : 'Monto descuento'"
										label-for="discount-amount"
									>
										<b-input-group
											:prepend="discount ? discount.type == 'Soles' ? 'S/. ' : '% ' : 'S/. '"
											class="input-group-merge"
										>
											<b-form-input
												id="discount-amount"
												v-model="discountAmount"
												class="form-control"
												type="number"
												min="0"
												step="1"
												:disabled="discount ? false : true"
												trim
											/>
										</b-input-group>		
									</b-form-group>
								</b-col>

								<!-- The product have variants? -->
								<b-col
									cols="12"
									md="12"
								>
									<b-row>

										<!-- Have Variants -->
										<b-col
											cols="6"
											md="6"
										>
											<b-form-group
												label="¿Tiene variantes?"
												label-for="variants"
											>
												<validation-provider
													#default="{ errors }"
													rules="required"
													name="tiene variantes"
												>
													<v-select
														id="variants"
														v-model="variant"
														:options="variantOptions"
														:state="errors.length > 0 ? false:null"
														label="label"
													/>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>		
											</b-form-group>
										</b-col>

										<!-- Product Price --> 
										<b-col
											cols="3"
											md="3"
											v-if="variant && variant.label == 'NO'"
										>
											<b-form-group
												label="Precio"
												label-for="product-price"
											>
												<validation-provider
													#default="{ errors }"
													rules="required"
													name="precio del producto"
												>
													<b-input-group
														prepend="S/. "
														class="input-group-merge"
													>
														<b-form-input
															id="product-price"
															v-model="price"
															class="form-control"
															placeholder="Precio del producto ..."
															:state="errors.length > 0 ? false : null"
															trim
														/>						
													</b-input-group>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</b-form-group>
										</b-col>

										<!-- Have Sub Variants --> 
										<b-col
											cols="3"
											md="3"
											v-if="variant && variant.label == 'SÍ'"
										>
											<b-form-group
												label="¿Tiene sub-variantes?"
												label-for="subvariants"
											>
												<validation-provider
													#default="{ errors }"
													rules="required"
													name="tiene sub-variantes"
												>
													<v-select
														id="subvariants"
														v-model="subvariant"
														:options="variantOptions"
														:state="errors.length > 0 ? false:null"
														label="label"
													/>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>	
											</b-form-group>
										</b-col>

									</b-row>

									<b-row v-if="subvariant">
										<!-- Add Variant Button -->
										<b-col md="12">
											<b-button
												@click="addVariant"
												size="sm"
												v-ripple.400="'rgba(255, 255, 255, 0.15)'"
												variant="primary"
											>
												AGREGAR VARIANTE
											</b-button>
											<hr>
											<template v-if="subvariant.label =='NO'">
												<div v-for="(item, index) in variants" :key="item.id">
													<b-input-group prepend="Variante" :class="{ 'mb-2': (index+1 != variants.length) }">
														<b-form-input v-model="item.name" placeholder="Nombre de la variante ..."></b-form-input>
														<b-form-input v-model="item.price" placeholder="Precio de la variante ..."></b-form-input>
														<b-input-group-append>
															<b-button size="sm" variant="danger" @click="removeVariant(item.id)">
																<feather-icon icon="Trash2Icon" />
															</b-button>
														</b-input-group-append>
													</b-input-group>
												</div>
											</template>
											<template v-else>
												<div v-for="(variant, index) in variants" :key="variant.id">
													<b-input-group prepend="Variante" :class="{ 'mb-2': variant.subvariants.length }">
														<b-form-input v-model="variant.name" placeholder="Nombre de la variante ..."></b-form-input>
														<b-input-group-append>
															<b-button size="sm" variant="success" @click="addSubVariant(variant.id)">
																AGREGAR SUB-VARIANTE
															</b-button>
															<b-button size="sm" variant="danger" @click="removeVariant(variant.id)">
																<feather-icon icon="Trash2Icon" />
															</b-button>
														</b-input-group-append>
													</b-input-group>
													<div v-for="subvariant in variant.subvariants" :key="subvariant.id">
														<b-input-group prepend="Sub-Variante">
															<b-form-input v-model="subvariant.name" placeholder="Nombre de la sub-variante ..."></b-form-input>
															<b-form-input v-model="subvariant.price" placeholder="Precio de la sub-variante ..."></b-form-input>
															<b-input-group-append>
																<b-button size="sm" variant="danger" @click="removeSubVariant(variant.id, subvariant.id)">
																	<feather-icon icon="Trash2Icon" />
																</b-button>
															</b-input-group-append>
														</b-input-group>
													</div>
													<hr v-if="(index+1 != variants.length)">
												</div>
											</template>
										</b-col>
									</b-row>
								</b-col>
								
							</b-row>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<!-- Action Buttons -->
							<b-button
								@click="addProduct"
								variant="primary"
								class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-1"
								:block="$store.getters['app/currentBreakPoint'] === 'xs'"
							>
								AGREGAR
							</b-button>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>

		</b-card>
	</section>
</template>

<script>

	import { ValidationProvider, ValidationObserver } from 'vee-validate';
	import "vue2-dropzone/dist/vue2Dropzone.min.css";
	import Cleave from 'vue-cleave-component';
	import Ripple from 'vue-ripple-directive';
	import vue2Dropzone from 'vue2-dropzone';
	import { quillEditor } from 'vue-quill-editor';
	import { required } from '@validations';
	import { v4 as uuidv4 } from 'uuid';
	import vSelect from 'vue-select';
	import Swal from 'sweetalert2';
	import axios from 'axios';
	
	export default {
		components: {
			ValidationProvider,
            ValidationObserver,
			Cleave,
			// 3rd party
			vSelect,		
			quillEditor,
			vueDropzone: vue2Dropzone,

			required
		},
		data() {
			return {
				name: '',
				description: '',
				description_seo: '',
				snowOption: {
					theme: 'snow',
				},			
				product_type: '',
				product_typeOptions: [],
				category: '',
				categoryOptions: [],
				brand: '',
				brandOptions: [],
				tag: [],
				tagOptions: [],
				variant: null,
				subvariant: null,
				variantOptions: [{
					_id: 1,
					label: 'SÍ'
				}, {
					_id: 0,
					label: 'NO'
				}],
				discount: '',
				discountAmount: 0,
				discountOptions: [],
				price: '',
				stock: '',
				img: '',
				images: [],
				variants: [],
				// subvariants: [],
					
				options: {
					amount: {
						numeral: true,
						// numeralDecimalMark: '.',
						numeralDecimalScale: 2,
						numeralPositiveOnly: true
					},	
					stock: {
						numeral: true,
						numeralPositiveOnly: true
					},
				},
				
				headers: { 'Content-Type': 'multipart/form-data' },
				dropzoneOptions: {
					url: `${process.env.VUE_APP_HOST}/api/dashboard/product/create`,
					paramName: this.paramNameForSend,
					autoDiscover: false,
					maxFiles: 10,
					maxThumbnailFilesize: 10,
					parallelUploads: 10,
					acceptedFiles: ".webp,",				
					addRemoveLinks: true,		
					autoProcessQueue: false,					
					thumbnailWidth: 140,
					thumbnailHeight: 140,
					dictRemoveFile: "Eliminar",
					uploadMultiple: true
				}
			}
		},
		directives: {
			Ripple
		},
		watch: {
			discount (){
				this.discountAmount = 0;
			},
			variant (newValue){
				this.variants = [];
				this.subvariant = null;
				this.price = '';
				if (newValue && newValue._id == 1){
					this.variants.push({
						id: uuidv4(),
						name: null,
						price: null
					});
				}
			},
			subvariant (newValue){
				this.variants = [];
			}
		},
		beforeMount(){
			this.getCategories();
			this.getBrands();
			this.getProductTypes();
			this.getTags();
			this.getDiscountTypes();
		},
		methods: {
			paramNameForSend(){
				return "images";
			},
			vsendingMultiple(files, xhr, formData) {
				
				const tagArr = this.tag.map(item => item._id);
				let priceUnit = this.price;

				if (this.variant._id){
					if (this.subvariant._id){
						priceUnit = this.variants[0].subvariants[0].price;
					} else {
						priceUnit = this.variants[0].price;
					}
				}

				formData.append('name', this.name);
				formData.append('description', this.description);
				formData.append('description_seo', this.description_seo);
				formData.append('isVariant', (this.variant._id ? true : false));
				formData.append('isSubVariant', (this.subvariant ? (this.subvariant._id ? true : false) : false));
				formData.append('price_init', priceUnit);
				formData.append('stock', this.stock);
				formData.append('variants', JSON.stringify(this.variants));
				formData.append('product_type', this.product_type["_id"]);
				formData.append('category', this.category["_id"]);
				formData.append('brand', this.brand["_id"]);
				formData.append('discount', this.discount["_id"]);
				formData.append('discountAmount', this.discountAmount);
				formData.append('tag', tagArr);

			},	
			vsuccess(files, response){		
				if(response) {
					Swal.fire({
						title: 'Creado!',
						text: 'El producto ha sido creado exitosamente.',
						timer: 3000,
						icon: 'success',
						customClass: {
							confirmButton: 'btn btn-sucess',
						},
						buttonsStyling: false,
						showConfirmButton: false,
					});
					this.$refs['myVueDropzoneRef'].removeFile(files);    
					this.$router.push({ name: 'products' });
				}
			},
			vfilesAdded(files){
				if(files){
					this.images = files;
				}
			},
			verror(file, message, xhr){
				/* console.log(message) */
			},
			verrorMultiple(files, message, xhr){
				/* console.log(message) */
			},
			vcanceled(file){
				/* console.log(file) */
			},
			async getCategories(){
				let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/categories`);
				this.categoryOptions = response.data;
			},
			async getBrands(){
				let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/brands`);
				this.brandOptions = response.data;
			},
			async getProductTypes(){
				let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/product-types`);
				this.product_typeOptions = response.data;
			},
			async getTags(){
				let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/tags`);
				this.tagOptions = response.data;
			},
			async getDiscountTypes(){
				let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/discounts`);
				this.discountOptions = response.data;
			},
			addVariant (){
				if (this.subvariant._id == 1){

					this.variants.push({
						id: uuidv4(),
						name: null,
						subvariants: []
					});

				} else {

					this.variants.push({
						id: uuidv4(),
						name: null,
						price: null
					});

				}
			},
			addSubVariant (variantId){

				const variant = this.variants.find(variant => variant.id === variantId);

				variant.subvariants.push({
					id: uuidv4(),
					name: null,
					price: null
				});

			},
			removeVariant (id){
				this.variants = this.variants.filter(i => i.id != id);
			},
			removeSubVariant (variantId, subVariantId){
				const variant = this.variants.find(variant => variant.id === variantId);

				variant.subvariants = variant.subvariants.filter(subvariant => subvariant.id != subVariantId);
			},
			validateVariants (){
				let errors = false;

				this.variants.map(variant => {
					if (!variant.name || variant.name == '' || variant.name == ' ' || !variant.price){
						errors = true;
					}
				});

				return errors;
			},
			addProduct(){
				this.$refs.simpleRules.validate()
                    .then(success => {
                        if (success) {

							if (this.subvariant && this.subvariant._id){

								let error, error2, error3, error4 = false;
								let notSubVariants = [];

								if ((this.variant._id && !this.variants.length)){
									error = true;
								}

								if (!error){
									this.variants.map((variant, index) => {
										if (!variant.subvariants.length){
											error2 = true;
											notSubVariants.push((index + 1));
										}
										if (!variant.name || variant.name == '' || variant.name == ' '){
											error3 = true;
										}
										variant.subvariants.map(subvariant => {
											if (!subvariant.name || subvariant.name == '' || subvariant.name == ' ' || !subvariant.price){
												error3 = true;
											}
										});
									});
								}

								if(this.images.length <= 0){
									error4 = true;
								}

								if(error || error2 || error3 || error4) {
									let message = null;

									if (error) {
										message = '¡Debe cargar al menos una variante!';
									} else if (error2){
										message = `¡Debe cargar al menos una sub-variante para las variantes: ${notSubVariants.join(', ')}!`;
									} else if (error3){
										message = '¡Debe completar toda la información de las variantes y subvariantes cargadas!'
									} else if (error4){
										message = '¡Debe cargar al menos una imagen!';
									}

									Swal.fire({
										title: 'Error',
										text: message,
										icon: 'error',
										customClass: {
											confirmButton: 'btn btn-danger',
										},
										buttonsStyling: false,
										showConfirmButton: false
									});
								} else {
									this.$refs['myVueDropzoneRef'].processQueue();
								}

							} else {

								let error, error2 = false;

								const validateVariants = this.validateVariants();

								if ((this.variant._id && !this.variants.length) || validateVariants) {
									error = true;
								}
					
								if(this.images.length <= 0){
									error2 = true
								}
								
								if(error || error2) {
									let message = null;
									if (error) {
										message = validateVariants ? '¡Debe completar toda la información de las variantes cargadas!' : '¡Debe cargar al menos una variante!';
									} else {
										message = '¡Debe cargar al menos una imagen!';
									}
									Swal.fire({
										title: 'Error',
										text: message,
										icon: 'error',
										customClass: {
											confirmButton: 'btn btn-danger',
										},
										buttonsStyling: false,
										showConfirmButton: false
									});
								} else {				
									this.$refs['myVueDropzoneRef'].processQueue();
								}

							}

						}
                });			
			}
		}
	}

</script>

<style>

	.vue-dropzone {
		border-radius: 15px !important;
		border-style: dotted !important;
		border: 2px solid #3b4253 !important;
	}
	
	.dropzone {
		background: transparent !important;
	}
	
	.dropzone .dz-preview .dz-image {
		border-radius: 10px !important; 
	}

	.dropzone .dz-preview.dz-image-preview{
		background: transparent !important;
	}
	
	.vue-dropzone>.dz-preview .dz-details{
		background: transparent !important;
	}

	.vue-dropzone>.dz-preview .dz-remove {
		margin-left: 22px !important;
	}
	
	.ql-container {
		min-height: 200px;
	}

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
	@import '@core/scss/vue/libs/quill.scss';
</style>